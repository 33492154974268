import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-size:cover;
  width: calc(100% - 80px);
  padding-right: 40px;
  padding-left: 40px;
  /* padding-top: 80px; */
  .inner {
    
    max-width: 1004px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: stretch;
    align-content: stretch;

    .item {      
      /* margin-bottom: 32px; */
      .icon {
        margin: 0 auto;
        width: auto;
        display: block;
        margin-bottom: 15px;
      }
      .label {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 42px;
        text-align: center;
        color: #007DBA;
      }
    }

    @media (max-width: 1000px) {
        max-width: 500px;
    }
  }
`;

function list(props) {

  return (
    <Wrapper>
      <div className={`inner`}>
        {props.data.map( (row, index) => 
          <div className="item" key={index}>
            <img className="icon" src={row.image.publicURL} />
            <h4 className="label">{row.label}</h4>
          </div>
        )}
      </div>
    </Wrapper>
  );
}

export default list;
